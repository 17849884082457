import { Link } from "gatsby"
import Grid from '@material-ui/core/Grid'
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Shield from "../images/stalbans-shield.png"

const HeaderContainer = styled(Grid)`
  margin-bottom: 1 rem;
  background-color: 'black';
  width: 100%;
  height: 45px;
`
const LogoText = styled.div`
  width: 100%;
  font-family: raleway;
  font-style: normal;
  font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  margin-left: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
`
const LogoContainer = styled.div`
  margin-right: 10px;
  padding-left: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  padding-right: 0;
  float: left;
`

const LogoImage = styled.img`
  width: calc(60px + (100 - 60) * ((100vw - 300px) / (1600 - 300)));
  margin-right: 10px;
  padding-left: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  float: left;
`

const HeaderMenu = styled.div`
  padding-top: calc(5px + (10 - 5) * ((100vw - 300px) / (1600 - 300)));
  float: left;
`

const MenuLink = styled.div`
  padding-left: calc(10px + (30 - 10) * ((100vw - 300px) / (1600 - 300)));
  color: #f2f2f2;
  float left;
  Font-Family: raleway;
  font-style: normal;
  font-weight: bold;
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (2 - 1) * ((100vw - 300px)/(1600 - 300)));
  position: relative;
`

const Header = ({ siteTitle }) => {
  return (
    <HeaderContainer container>
      <Grid item xs={5} md={3}>
        <LogoContainer>
          <LogoImage
            src={Shield}
            alt="Episcopal Shield"
          />
          <LogoText>
            St.Alban's <br /> Episcopal Church
          </LogoText>
        </LogoContainer>
      </Grid>
      <Grid item xs={7} md={9}>
        <HeaderMenu>
          <Link to="/AboutUs">
            <MenuLink>About Us</MenuLink>
          </Link>
          <Link to="/Calendar">
            <MenuLink>Calendar</MenuLink>
          </Link>
          <Link to="/SpiritualGrowth">
            <MenuLink>Spiritual Growth</MenuLink>
          </Link>
          <Link to="/Videos">
            <MenuLink>Videos</MenuLink>
          </Link>
          <Link to="/Community">
            <MenuLink>Community</MenuLink>
          </Link>
          <Link to="/News">
            <MenuLink>News</MenuLink>
          </Link>
          <Link to="/MailingList">
            <MenuLink>Mailing List</MenuLink>
          </Link>
        </HeaderMenu>
      </Grid>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import PageHeader from "../components/PageHeader"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OpenDoors from "../images/open-doors.jpg"
import { Info, PageHeaderText } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${OpenDoors});
  background-size: cover;
  display: flex;
`
const TextContainer = styled(Grid)`
  height: 65vh;
  background: black;
  padding: 20px 0 20px 10px;
`
const FormContainer = styled(Grid)`
  background: black;
  padding: 10px 30px 10px 10px;
`
const CustomFormContainer = styled.div`
  width: 100%;
  float: right;
  background: #efefef;
  border-radius: 2px;
  padding: 10px;
  display: inline-block;
`
const NameInput = styled.input`
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding: 5px;
  width: 100%;
`
const EmailInput = styled.input`
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding: 5px;
  margin-top: 10px;
  width: 100%;
`
const Button = styled.button`
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  padding: 10px;
  margin-top: 10px;
`

const CustomForm = ({ status, message, onValidated }) => {
  let email, name
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
    })

  return (
    <CustomFormContainer>
      <SEO title="MailingList" />
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <NameInput
        ref={node => (name = node)}
        type="text"
        placeholder="Your name"
      />
      <br />
      <EmailInput
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <br />
      <Button
        style={{ fontSize: "1em", padding: 5, marginTop: 5 }}
        onClick={submit}
      >
        Submit
      </Button>
    </CustomFormContainer>
  )
}

const MailingList = () => {
  return (
    <Layout>
      <SEO title="Mailing List" />
      <Grid container>
        <TitleImage item xs={12}>
          <PageHeaderText>Mailing List</PageHeaderText>
        </TitleImage>
        <TextContainer item xs={6} md={8}>
          <Info>
            The mailing list is used to send out information about the parish and is intended for both existing
            members as well as those that are interested in what we are doing.  There is usually 1 email a week 
            unless there are updates that are needed
          </Info>
        </TextContainer>
        <FormContainer item xs={6} md={4}>
          <MailchimpSubscribe
            url="https://stalbans-sussex.us13.list-manage.com/subscribe/post?u=689e48be07228b932d14b6d36&amp;id=2560b008e5"
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </FormContainer>
      </Grid>
    </Layout>
  )
}

export default MailingList

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { useLocation } from "@reach/router"
import PageHeader from "./PageHeader"
import Header from "./header"
import "./layout.css"
import Footer from "./footer"
import parishImage from "../images/parish_photo.jpg"
import firebase from "gatsby-plugin-firebase"

const FirstPageContainer = styled.div`
  margin: 0 auto;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
      180deg,
      #000000 0%,
      rgba(51, 121, 200, 0.85) 35.42%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    url(${parishImage});
  background-size: cover;
`
const MainContainer = styled(Grid)`
  color: white;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
`
const FooterContainer = styled(Grid)`
  text-align: center;
  color: darkblue;
`

const Layout = ({ children, ...rest }) => {
  const location = useLocation();

  React.useEffect(() => {
    firebase.analytics().logEvent(location)
  }, [location])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      {location.pathname === "/" && (
        <FirstPageContainer container>
          <Grid xs={12}>
            <Header siteTitle={data.site.siteMetadata.title} />
          </Grid>
          <Grid xs={12}>{children}</Grid>
          <FooterContainer item xs={12}>
            <Footer />
          </FooterContainer>
        </FirstPageContainer>
      )}
      {location.pathname !== "/" && (
        <MainContainer container>
          <Grid xs={12}>
            <PageHeader siteTitle={data.site.siteMetadata.title} />
          </Grid>
          <Grid xs={12}>{children}</Grid>
          <FooterContainer item xs={12}>
            <Footer />
          </FooterContainer>
        </MainContainer>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

export const PageHeaderContainer = styled.div`
  text-align: center;
`
export const PageHeaderText = styled.div`
  color: #f2f2f2;
  font-family: Raleway;
  text-align: center;
  font-size: calc(24px + (74 - 24) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;

  height: auto;
  margin: auto;
`
export const InfoHeader = styled.h1`
  margin-top: 10px;
  color: #f2f2f2;
  font-family: Raleway;
  text-align: left;
  font-size: calc(18px + (32 - 18) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
`
export const InfoInside = styled.div`
  width: 60%;
  height: auto;
  margin: auto;
`
export const Info = styled.h1`
  color: #F2F2F2;
  Font-family: Raleway;
  Text-align: left;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.2em + (1.5 - 1.2) * ((100vw - 300px) / (1600 - 300)));  
  font-weight: normal;
`
export const Blue = styled.span`
  color: #74b9ff;
`
export const Red = styled.span`
  color: #bb2d31;
`
export const InfoContainerEven = styled(Grid)`
  padding: "10px 0";
  min-height: 40vh;
  background: #0d0d0d;
  width: 100%;
  display: flex;
`
export const InfoContainerOdd = styled(Grid)`
  padding: "10px 0";
  min-height: 40vh;
  background: #181818;
  width: 100%;
  display: flex;
`
export const LinkButton = styled.div`
  height: auto;
  padding: 20px;
  width: Auto;
  background: #ffffff;
  color: Black;
  text-align: center;
  margin: auto;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
`
export const ButtonContainerEven = styled(Grid)`
  height: 20vh;
  background: #181818;
  width: 100%;
  display: flex;
`
export const ButtonContainerOdd = styled(Grid)`
  minheight: 20vh;
  background: #0d0d0d;
  width: 100%;
  display: flex;
`
export const SectionHeader = styled.div`
  color: #F2F2F2;
  Font-family: Raleway;
  text-align: center;
  font-size: calc(24px + (60 - 24) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  Margin-top: 3%;
  Margin-bottom: 3%;
`
export const SectionHeadingContainer = styled.div`
  width: 50%;
  float: left;
  display: inline;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
`
export const Description = styled.h1`
  color: #ffff;
  font-family: Raleway;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin-bottom: 1%;
`
export const SubHeading = styled.h1`
  color: #bb2d31;
  font-family: Raleway;
  text-align: center;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  min-height: 50px;
  font-weight: bold;
  margin-bottom: 1%;
`
export const SubText = styled.p`
  color: #f2f2f2;
  font-family: Raleway;
  text-align: center;
  font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.5em + (1.5 - 1.5) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin-bottom: 1%;
`
export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  width: auto;
  padding: 2vh 2vw;
  background: rgba(51, 51, 51, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 2.7vh;
`
